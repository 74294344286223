/* LoginForm.module.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    width: 300px;
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 5px;
  }
  
  .label {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    position: relative; /* Add this line */
  }
  
  .input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .button {
    background-color: #3498db;
    color: white;
    padding: 10px;
    font-size: 14px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: #2980b9;
  }
  
  .message {
    text-align: center;
    margin-bottom: 10px;
  }

  .error{
    color: red;
  }
  
  .passwordInput {
    padding-right: 40px;
  }
  
  .togglePassword {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 10px;
    top: calc(50%);
    cursor: pointer;
  }

  .forgotPasswordButton {
    background-color: transparent;
    color: #3498db;
    border: none;
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 10px;
    padding: 0;
    transition: color 0.3s;
  }
  
  .forgotPasswordButton:hover {
    color: #2980b9;
  }