.wrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 95vw;
  margin: auto;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  padding: 20px;
}

.textarea {
  width: 100%;
  height: 300px;
  padding: 10px;
  font-size: 18px;
  border: none;
  border-bottom: 2px solid #ddd;
  margin-bottom: 20px;
  font-family: sans-serif;
}

.button {
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: #53c8ff;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
}

.resume {
  width: 100%;
  height: auto;
  min-height: 400px;
  background-color: #fff;
  border-radius: 5px;
  overflow: auto;
}

.experience-wrappers {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.options-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.experience-checkbox,
.generate-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.experience-checkbox input,
.generate-options input {
  margin-right: 5px;
}

.generate-options {
  justify-content: space-between;
}

.select-field{
  margin: 0 0 0 10px;
  padding: 5px;
}

.input-field {
  width: 100%;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-bottom: 2px solid #ddd;
  margin-bottom: 20px;
  font-family: sans-serif;
}

.char-count {
  text-align: right;
}

.error {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: -10px;
}

.help-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.help-icon{
  width: 25px;
  height: 25px;
  padding: 2px 2px 0 0;
}

.help-icon:hover{
  width: 30px;
  height: 30px;
  padding: 0;
}

.no-pers-info-wrapper{
  display: flex;
  justify-content: flex-end;
  width: 200px;
}

.no-pers-info{
}

.link{
  color: #5cdb95;
  font-weight: bold;
}

@keyframes flash {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.flash {
  animation: flash 1s linear infinite;
}

@media (max-width: 1200px) {
  .experience-wrappers {
    flex-direction: column;
  }

  .input-field {
    width: 100%;
  }
}
