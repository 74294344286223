.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #34495e;
  padding: 10px 30px;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  position: relative;
}

.resum{
  color: #53c8ff;
}

.ai{
  color: #5cdb95;
  margin: 0;
}

.logo {
  font-size: 24px;
  color: white;
  text-decoration: none;
}

.nav {
  display: flex;
  align-items: center;
}

.link {
  color: white;
  text-decoration: none;
  font-size: 18px;
  margin-left: 20px;
  transition: color 0.3s;
}

.link:hover {
  color: #bdc3c7;
}

span {
  margin: 0 0 0 10px;
}

.email {
  cursor: pointer;
  margin: 0 10px 0 20px;
}

.dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 30px;
  top: 50px;
  background-color: #34495e;
  border: 1px solid #2c3e50;
  border-radius: 5px;
  padding: 10px 20px;
  z-index: 10;
}

.dropdown .link {
  margin: 5px 0;
}

.hamburger {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .header {
    padding: 10px 15px;
  }

  .tokens {
    margin: 0 20px 0 auto;
    font-size: 1.25rem;
    color: white;
    text-decoration: none;
    transition: color 0.3s;
  }

  .logo {
    font-size: 1.5rem;
  }

  .nav {
    display: none;
  }

  .showNav {
    display: flex;
    flex-direction: column;
    background-color: #34495e;
    position: absolute;
    right: 15px;
    top: 50px;
    border: 1px solid #2c3e50;
    border-radius: 5px;
    padding: 10px 20px;
    z-index: 10;
  }

  .link {
    font-size: 14px;
    margin-left: 0;
    margin-bottom: 10px;
  }

  .link:hover {
    color: #bdc3c7;
  }

  .email {
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
  }


  .dropdown {
    right: 15px;
  }

  .hamburger {
    display: block;
  }
}
