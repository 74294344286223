.wrapper{
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 60vh;
    justify-content: space-evenly;
    margin: auto;
    text-align: center;
}

.link{
    color: #5cdb95;
    font-weight: bold;
    text-decoration: none;
    padding: 0 0.5rem;
}

.link:hover{
    color: #3d8a6b;
    font-weight: 1000;
}

.contentWrapper {
    background-color: #2c2c2c;
    color: #f0f0f0;
    padding: 20px;
    font-size: 16px;
    line-height: 1.5;
  }
  
.title{
    font-weight: bold;
    font-size: 36px;
    margin-top: 20px;
}

  .header {
    font-weight: bold;
    font-size: 24px;
    margin-top: 20px;
  }

  a{
    text-decoration: none;
    color: #53c8ff;
  }

  a:hover{
    color: #00aeff
  }