/* Progressive blur class */
.coverletterWrapper.blurred::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0.8) 20%, rgba(255,255,255,0.9) 80%, rgba(255,255,255,1) 100%);
    backdrop-filter: blur(1.5px);
    pointer-events: none;
  }
  
  .coverletterWrapper {
    position: relative;  /* Ensure ::before is positioned relative to this container */
  }
  
  .preventCopy {
    user-select: none;  /* Prevent text selection (i.e., non-highlightable) */
    pointer-events: none;  /* Make it non-interactive */
  }