.container {
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  .inputGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  
  .label {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .input {
    border: 2px solid #ccc;
    border-radius: 5px;
    padding: 0.5rem;
    font-size: 1rem;
    transition: border-color 0.3s ease;
  }
  
  .input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
  }
  
  .updateButton {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    margin-top: 1rem;
  }
  
  .updateButton:hover {
    background-color: #0056b3;
  }

  .note{
    font-weight: bold;
  }
  