.download-pdf-container {
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
}

.download-pdf-icon {
  fill: currentColor;
  transition: fill 0.3s ease;
  opacity: 0.5;
}

.download-pdf-container:hover .download-pdf-icon {
  fill: #007bff;
  opacity: 1;
}

.hasNotPurchased{
  display: none;
}