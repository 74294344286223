.container {
    display: flex;
  }
  
  .descriptionList {
    flex: 1;
    overflow-y: auto;
    min-height: 95vh;
    padding: 1rem;
    border-right: 2px solid #ccc;
    background-color: #f8f9fa;
    margin-top: 0;
  }
  
  .descriptionListItem {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .descriptionListItem:hover {
    background-color: #e9ecef;
    cursor: pointer;
  }
  
  .selectedDescription {
    background-color: #e9ecef;
    font-weight: bold;
  }
  
  .description {
    flex: 3;
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
  
  .editableBox {
    flex: 1;
    min-height: 300px; /* Set a minimum height for the editable box */
    resize: vertical; /* Allow vertical resizing */
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 1rem;
    font-size: 1rem;
    margin-bottom: 1rem;
    font-family: sans-serif;
  }
  
  .saveButton {
    align-self: flex-start;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .saveButton:hover {
    background-color: #0056b3;
  }

  .inputContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .addButton {
    display: block;
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    background-color: #5cdb95;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
    text-align: center;
  }
  
  .addButton:hover {
    background-color: #3d8a6b;
  }

  .nameInput {
    display: block;
    width: 95%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    font-family: sans-serif;
  }

  @media (max-width: 1200px) {
    .container {
      display: flex;
      flex-direction: column;
    }

    .inputContainer {
      position: sticky; /* Add this line */
      top: 0; /* Add this line */
      background-color: #f8f9fa; /* Add this line */
      z-index: 1; /* Add this line */
      padding: 1rem;
    }

    .descriptionList {
      padding-top: 1rem;
      overflow-y: hidden;
      overflow-x: auto;
      white-space: nowrap;
      min-height: auto;
      height: 60px; /* Adjust this value according to your preference */
      border-right: none;
      border-bottom: 2px solid #ccc;
    }
  
    .descriptionListItem {
      display: inline-block;
      margin-right: 0.5rem;
    }

    .editableBox {
      min-height: 55vh;
    }
  }
  
  