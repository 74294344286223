/* Landing.module.css */
.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom right, #53c8ff, #0080ff);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  }
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    background: white;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }

  .form{
    width: 80%;
  }
  
  .title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #05386B;
  }

  .resum{
    color: #53c8ff;
  }
  
  .ai{
    color: #5cdb95;
    margin: 0;
  }
  
  .subtitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 40px;
    color: #379683;
  }
  
  .label {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #05386B;
  }
  
  .textarea {
    width: 100%;
    height: 200px;
    padding: 10px;
    margin-bottom: 30px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-family: sans-serif;
  }
  
  .button {
    background-color: #5CDB95;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .link {
    font-size: 2rem;
    font-weight: bold;
    display: block;
    width: 220px;
    margin: auto;
  }

  .link:hover{
    color: #2980b9;
  }

  .additionalSection {
    margin-top: 40px;
    text-align: left;
    width: 100%;
  }
  
  .additionalSection h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #05386B;
  }
  
  .additionalSection p {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 20px;
    color: #5C7EA2;
  }
  
  .hr {
    width: 100%;
    margin: 50px 0;
  }
  
  @media (max-width: 1200px) { 
    .textarea{
        width: 95%;
    }
  }