.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.popup {
  background-color: white;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  width: 60%;
  max-width: 500px;
  position: relative;
}

.link{
  display: block;
  margin: auto;
  width: 120px;
}

.button {
  background-color: #5CDB95;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #5CDB95;
}
