.wrapper{
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 60vh;
    justify-content: space-evenly;
    margin: auto;
    text-align: center;
}

.link{
    color: #5cdb95;
    font-weight: bold;
    text-decoration: none;
    padding: 0 0.5rem;
}

.link:hover{
    color: #3d8a6b;
    font-weight: 1000;
}

.contentWrapper {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.header {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 40px 0 10px;
}

.articleImage {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 20px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

  a{
    text-decoration: none;
    color: #53c8ff;
  }

  a:hover{
    color: #00aeff
  }