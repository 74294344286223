.nav {
    display: flex;
  }
  
  .link {
    color: white;
    text-decoration: none;
    font-size: 18px;
    margin-left: 20px;
    transition: color 0.3s;
  }
  
  .link:hover {
    color: #bdc3c7;
  }
  
  @media (max-width: 1200px) {
    .nav{
      width: 100%;
    }
    .link {
      font-size: 16px;
      margin: 10px 0 0 0;
      border-top: 1px solid white;
      width: 100%;
      text-align: center;
      padding-top: 5px;
    }
  
    .link:hover {
      color: #bdc3c7;
    }
  
  }