/* PaymentSettings.css */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  
  .description {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px; /* Adjust based on your design preference */
    background-color: #f8f9fa;
    border-radius: 5px;
    padding: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: for better box visualization */
  }
  
  .description h1, .description h2 {
    color: #333; /* Adjust based on your design preference */
    margin-bottom: 1rem;
  }
  
  .portalButton {
    display: block; /* Make the button fit the container width */
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    background-color: #5cdb95; /* Different from the 'addButton', you might want a color indicating cancellation */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    text-decoration: none; /* Remove underline from links, if you're using a link styled as a button */
  }
  
  .portalButton:hover {
    background-color: #3d8a6b; /* Darken the color a bit on hover */
  }
