.container {
  display: flex;
}

.documentList {
  flex: 1;
  padding: 1rem;
  border-right: 2px solid #ccc;
  background-color: #f8f9fa;
}

.dropdownHeader {
  font-weight: bold;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
}

.desktopItemsContainer {
  display: flex;
  flex-direction: column;
}

/* Mobile view */
.mobileItemsContainer {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.documentListItem {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.documentListItem:hover {
  background-color: #e9ecef;
  cursor: pointer;
}

.selectedDocument {
  background-color: #e9ecef;
  font-weight: bold;
}

.document {
  flex: 3;
  padding: 1rem;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .documentList {
    border-right: none;
    border-bottom: 2px solid #ccc;
  }

  .document {
    /* width: 100%; */
  }
}
