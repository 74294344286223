.paymentForm {
  max-width: 600px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.radioContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.radioLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.subscribed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-style: italic;
  color: gray;
}

.radioLabel:hover {
  background-color: #e9ecef;
}

.planName {
  font-size: 1.2rem;
  font-weight: bold;
}

.price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #007bff;
}

.savings {
  font-size: 1rem;
  font-weight: bold;
  color: #28a745;
}

.payNowButton {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  margin-top: 1rem;
}

.notDisabled:hover {
  background-color: #0056b3;
}

.disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Media query for mobile devices */
@media (max-width: 1200px) {
  .paymentForm {
    max-width: 90%;
  }

  .planName,
  .price,
  .savings {
    font-size: 1.4rem;
  }
}
