.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  
  .popup {
    background-color: white;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    width: 80%;
    max-width: 1000px;
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 150px); /* Adjust this value to control the space between the popup and the window edges */
  }
  
  .popupContent {
    overflow-y: auto;
    flex-grow: 1;
    padding-right: 10px; /* Optional: Adds some padding between the text and the scrollbar */
  }
  
  .closeButton {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #5CDB95;
  }
  