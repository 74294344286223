.footer {
    background-color: #333;
    color: white;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    height: 2rem;
  }
  
  .links {
    display: flex;
  }
  
  .link {
    color: white;
    text-decoration: none;
    padding: 0 0.5rem;
  }
  
  @media (max-width: 1200px) {
    .footer {
      flex-direction: column;
    }
  
    .links {
      margin-bottom: 0;
    }

    .copyRight{
        margin: 0;
    }
  }
  