/* SignupForm.module.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    width: 300px;
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 5px;
  }
  
  .label {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }

  .checkbox {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
  }
  
  .input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .button {
    background-color: #27ae60;
    color: white;
    padding: 10px;
    font-size: 14px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: #229954;
  }
  
  .error {
    text-align: center;
    color: red;
    margin-bottom: 10px;
  }
  
  .title {
    text-align: center;
    margin-bottom: 20px;
  }
  