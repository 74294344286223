/* Resume component styles */
.resumeWrapper {
  display: flex;
  flex-direction: column;
}

/* Progressive blur class */
.resumeWrapper.blurred::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0.5) 30%, rgba(255,255,255,0.7) 60%, rgba(255,255,255,0.9) 80%, rgba(255,255,255,1) 100%);
  backdrop-filter: blur(3px);
  pointer-events: none;
}

.resumeWrapper {
  position: relative;  /* Ensure ::before is positioned relative to this container */
}

.preventCopy {
  user-select: none;  /* Prevent text selection (i.e., non-highlightable) */
  pointer-events: none;  /* Make it non-interactive */
}

.skillsWrapper {}
.skillsHeader {
  font-size: 4vh;
  display: flex;
  margin: 0;
}

.skillsList {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 10px 0 30px;
}

.expWrapper {}
.expHeader {
  font-size: 4vh;
  display: flex;
  margin: 0;
}

.expSpanWrapper {
  display: flex;
  justify-content: flex-start;
  margin: 0 !important;
}

.expSpan {
  text-align: left;
  margin: -10px;
}

.eduWrapper {}
.eduHeader {
  font-size: 4vh;
  display: flex;
  margin: 0;
}

.eduSubWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 50vw;
}

.hr {
  margin: 0;
}

/* EduContainer component styles */
/* No specific styles needed */

/* JobContainer component styles */
.jobWrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
}

.jobContainer1 {
  display: flex;
}

.jobSubContainer1 {
  display: flex;
  justify-content: flex-start;
  flex-grow: 2;
}

.jobSubContainer2 {
  display: flex;
  justify-content: flex-start;
  width: 550px;
}

.jobContainer2 {
  display: flex;
  justify-content: flex-end;
}

.jobList {
  width: 500px;
}

/* PersonalInfoContainer component styles */
.personalInfoWrapper {
  width: 95%;
  margin: 0 auto 50px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.personalInfoName {
  font-size: 6vh;
}

.personalInfoInfo {
  margin: 0 50px 0 0;
}

.personalInfoUl {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
  height: 150px;
  font-size: 1rem;
}

/* Media queries for mobile devices */
@media screen and (max-width: 1200px) {
  .skillsHeader,
  .expHeader,
  .eduHeader {
    font-size: 1.5rem;
  }

  .skillsList{
    font-size: 0.75rem;
    padding: 0 10px 0 10px;
  }


  .personalInfoName {
    font-size: 1.75rem;
  }

  .personalInfoInfo{
    margin: 0;
  }

  .personalInfoUl {
    font-size: 0.75rem;
  }

  .expWrapper {}
.expHeader {

}

.expSpanWrapper {

}

.expSpan {

}
.jobWrapper {
width: 100%;
}

.jobContainer1 {

}

.jobSubContainer1 {
  font-size: 0.75rem;
}

.jobSubContainer2 {
  width: 70%;
  font-size: 0.75rem;
}

.jobContainer2 {

}

.jobList {
  width: 65%;
  padding: 0 10px 0 0;
  font-size: 0.75rem;
}

  .eduSubWrapper {
    grid-template-columns: 1fr 1fr 1fr;
    font-size: 1rem;
    width: 100%;
  }
}
